import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';
import { withConfig } from '../../config/';
import pdfPreview from '../../assets/pdf-file.png';
import { IonIcon } from 'components/UI/IonIcon';
import { downloadFile } from 'utilities/downloadFile';
import { getConfig } from 'config/config';
import { cn } from 'utilities/utils';
import { mediaHeight, mediaWidth, TMediaSize } from './AttachmentsGallery';

const { theme } = getConfig();
const styles = createStyles({
  container: {
    // position: 'relative',
    // flex: 1,
    // alignSelf: 'center',
    // width: '100%',
    // alignItems: 'center',
    // justifyContent: 'center',
    // flexDirection: 'column',
    // marginBottom: 20,
  },
  fileWrapper: {
    display: 'flex',
    cursor: 'pointer',
    backgroundColor: '#fff',
    borderColor: theme.BACKGROUND_SECONDARY,
    justifyContent: 'flex-start',
  },
  image: {
    width: 40,
    height: 40,
  },
  name: {
    display: 'inline-block',
    fontSize: 14,
    fontFamily: 'Roboto',
  },
  desc: {
    display: 'block',
    fontSize: 12,
    fontFamily: 'Poppins',
  },
});

interface Props extends WithStyles<typeof styles> {
  files: Array<any>;
  size: TMediaSize;
}

const PostDocuments: React.FC<Props> = ({ classes, files, size }) => {
  return (
    <div className={'mb-5 flex items-center flex-col gap-y-2'}>
      {files.map((file, i) => {
        if (!file) return <React.Fragment key={'empty_' + i} />;
        const isPdf = file.mimetype === 'application/pdf';
        const url = file.url || file.uri || file.path;

        const onClickHandler = () => (isPdf ? window.open(url) : downloadFile(url, file.fileName));

        return (
          <div
            key={file.name + i}
            className={cn(
              classes.fileWrapper,
              'border rounded-md duration-150 hover:scale-[100.5%] hover:bg-slate-50 truncate',
              file.thumb ? 'flex-col items-start' : 'flex-row items-center p-3',
            )}
            style={{ width: mediaWidth[size], height: mediaHeight[size] }}
            onClick={onClickHandler}
          >
            {file.thumb ? (
              <img
                alt="PDF"
                src={file.thumb}
                className={cn(
                  `w-full h-[75%] object-cover object-top bg-red-50 rounded-md border-b`,
                  size === 'xs' && 'h-full',
                  size === 'lg' && 'h-[85%]',
                )}
              />
            ) : isPdf ? (
              <img alt="PDF" src={pdfPreview} className={cn(classes.image)} />
            ) : (
              <IonIcon name="document-outline" size={42} />
            )}
            <div className="px-2 justify-center w-full">
              <p className={cn(classes.name)} style={{ color: theme.TEXT_PRIMARY }}>
                {file.fileName}
              </p>
              <span className={classes.desc} style={{ color: theme.TEXT_SECONDARY }}>
                {isPdf ? 'Klicken zum Öffnen' : 'Klicken Sie zum Herunterladen'}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default withStyles(styles)(withConfig(PostDocuments));
