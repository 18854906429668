import React from 'react';
import { getConfig } from '../../../config/config';
import { AttachmentsGallery } from '../../../components/Shared/AttachmentsGallery';

const { theme } = getConfig();

type Props = {
  value: {
    pdf?: string;
    video?: string;
    image?: string;
    link?: string;
  };
};

const ProfileAttachments: React.FC<Props> = ({ value }) => {
  if (!value) return <></>;
  if (!value.video && !value.image && !value.pdf) return <></>;

  const videos = value.video ? [value?.video] : [];
  const images = value.image ? [value?.image] : [];
  const documents = value.pdf ? [value?.pdf] : [];

  return (
    <>
      <div className="w-full h-[1px] mb-8" style={{ backgroundColor: theme.BACKGROUND_SECONDARY }} />
      <div className="overflow-hidden flex flex-row">
        <AttachmentsGallery videos={videos} images={images} documents={documents} size="xs" flexDirection="row" />
      </div>
    </>
  );
};
export default ProfileAttachments;
