import React, { FC, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { ArrowDownward } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { getConfig } from 'config/config';
import { DownloadButton } from './DownloadButton';
import { TMediaSize, mediaHeight, mediaWidth } from './AttachmentsGallery';

const { theme } = getConfig();
export const VideoPlayer: FC<{ videos: any; size?: TMediaSize }> = ({ videos, size }) => {
  const [videoSize, setVideoSize] = useState<null | string>(null);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const srcUri = videos[0]?.url;

  useEffect(() => {
    fetch(srcUri, { method: 'HEAD' })
      .then((res) => {
        const videoSize = Number(res.headers.get('Content-Length') || 0);
        const string = `${Math.round(videoSize / Math.pow(10, 4)) / 100}MB`;
        setVideoSize(string);
      })
      .catch((e) => console.log('FAILED TO FETCH VIDEO SIZE :', e));
  }, []);

  const LoadView = (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: theme.BUTTON_PRIMARY,
        width: mediaHeight[size] / 3.5,
        height: mediaHeight[size] / 3.5,
        borderRadius: 100,
      }}
    >
      <ArrowDownward fontSize={size === 'lg' ? 'large' : 'small'} htmlColor={'#fff'} />
      <p style={{ color: '#fff', fontWeight: 'bold', fontSize: size === 'lg' ? 14 : 10 }}>{videoSize}</p>
    </Box>
  );

  return (
    <div style={{ width: 'auto', height: 'auto', position: 'relative' }}>
      {videoIsPlaying && <DownloadButton {...videos[0]} style={{ top: 10, right: 10, position: 'absolute' }} />}
      <ReactPlayer
        url={videos[0]?.url}
        controls={true}
        playing={true}
        width={mediaWidth[size]}
        height={mediaHeight[size]}
        playIcon={LoadView}
        onPlay={() => setVideoIsPlaying(true)}
        light={videos[0]?.thumb}
      />
    </div>
  );
};
