import { Button } from 'components/shadcn/button';
import { Checkbox } from 'components/shadcn/checkbox';
import { NewDatePicker } from 'components/shadcn/datepicker';
import { Label } from 'components/shadcn/label';
import Survey, { TEventSurvey } from 'components/Shared/Survey/Survey';
import { ErrorLabel } from 'components/UI/ErrorLabel';
import { FormInput } from 'components/UI/FormInput';
import { FormikErrors, FormikTouched } from 'formik';
import { useLanguage } from 'languages/languageContext';
import moment from 'moment';
import { Moment } from 'moment';
import { FC, useState } from 'react';
import { useAppSelector } from 'store';

export type TEventPart = {
  _id?: string;
  title: string;
  start: Moment;
  end: Moment;
  location: string;
  description: string;
  price: string;
  maxMembers: number;
  questions?: TEventSurvey[];
  members?: {
    acceptedAt: string;
    _id: string;
    id: {
      _id: string;
      name: string;
      lastname: string;
      photo: string;
    };
  }[];
  waitList?: {
    joinDate: string;
    user: {
      _id: string;
      name: string;
      lastname: string;
      photo: string;
    };
  }[];
};

type Props = {
  values: TEventPart;
  errors: FormikErrors<TEventPart>;
  touched: FormikTouched<TEventPart>;
  handleChange: any;
  handleBlur: any;
  idx: number;
  setValues: (v: TEventPart) => void;
  onChangeDate: ({
    e,
    type,
    field,
    value,
  }: ({ e: string; type: 'time' } | { e: Date; type: 'date' }) & {
    field: 'startDate' | 'endDate' | string;
    value?: any;
  }) => void;
  deletePart: () => void;
};

export const initialSurvey: TEventSurvey = {
  type: 'single',
  text: '',
  isAnonym: false,
  options: [{ title: '' }, { title: '' }],
};

const EventPart: FC<Props> = (props) => {
  const { idx, handleChange, handleBlur, touched, values, errors, setValues, onChangeDate, deletePart } = props;
  const { event_participants_limitation, events_options_possible } = useAppSelector((state) => state.common);
  const [enableParticipantsLimit, setEnableParticipantsLimit] = useState(false);
  const { eventLabels, btn } = useLanguage();

  const [withEndDate, setWithEndDate] = useState(false);

  const addEndDate = () => {
    setWithEndDate(true);
    values.end = moment(values.start).add(5, 'minutes');
    setValues({ ...values });
  };

  const addMaxMembers = () => {
    if (!enableParticipantsLimit) {
      values.maxMembers = event_participants_limitation;
      setEnableParticipantsLimit(true);
    } else {
      values.maxMembers = 0;
      setEnableParticipantsLimit(false);
    }
    setValues({ ...values });
  };

  const formatNumber = (n: string) => {
    return n.replace(/\D/g, '');
  };

  const formatCurrency = (inputValue: string, blur?: boolean) => {
    if (inputValue === '') return '';

    let input_val = inputValue;

    // Check for decimal (in this case, a comma)
    if (input_val.indexOf(',') >= 0) {
      const decimal_pos = input_val.indexOf(',');
      let left_side = input_val.substring(0, decimal_pos);
      let right_side = input_val.substring(decimal_pos + 1);
      // Only keep numbers in the left and right sides
      left_side = formatNumber(left_side);
      right_side = formatNumber(right_side);
      // If blurring, ensure there are two digits after the comma
      if (blur) {
        right_side = right_side.padEnd(2, '0');
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);
      // Join the number with a comma
      input_val = left_side + ',' + right_side;
    } else {
      // No comma entered, just format the whole number
      input_val = formatNumber(input_val);
      // Add comma and 00 if blurring
      if (blur) {
        input_val += ',00';
      }
    }

    return input_val;
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const formattedValue = formatCurrency(value);
    setValue(formattedValue);
  };

  const handlePriceBlur = () => {
    const value = values.price;
    const formattedValue = formatCurrency(value, true);
    setValue(formattedValue);
  };

  const setValue = (value) => {
    values.price = value;
    setValues({ ...values });
  };

  return (
    <>
      <div className="flex flex-row my-2">
        <div className="w-2 bg-slate-200 rounded-l-sm" />
        <div className="w-full pl-2">
          <div className="flex flex-row justify-between mx-1 mb-3 items-end">
            <h4 className="font-medium">{eventLabels.parts.part}</h4>
            <Button variant="text" size="text" className="text-red-500" onClick={deletePart}>
              {eventLabels.parts.part} {btn.clearBtn.toLowerCase()}
            </Button>
          </div>
          <FormInput
            name={`parts[${idx}].title`}
            placeholder={eventLabels.titleLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.title}
            error={errors?.title}
            touched={touched?.title}
          />
          <div className="flex flex-row gap-4">
            <div className="flex-1">
              <NewDatePicker
                name={'start'}
                placeholder={eventLabels.startLabel}
                date={values.start}
                setDate={(e) => {
                  onChangeDate({ e, field: `parts[${idx}].start`, type: 'date', value: values.start });
                }}
                fromYear={+moment().format('YYYY')}
                toYear={2099}
              />
            </div>
            <div>
              <FormInput
                aria-label="Time"
                type="time"
                value={values.start?.format('HH:mm')}
                name="start"
                onChange={(e) => {
                  onChangeDate({ e: e.target.value, field: `parts[${idx}].start`, type: 'time', value: values.start });
                }}
              />
            </div>
          </div>
          <ErrorLabel>{touched?.start && errors?.start}</ErrorLabel>
          {!withEndDate ? (
            <div className="w-full flex justify-center">
              <Button variant="link" className="text-sm -mt-5 w-fit self-center" onClick={addEndDate}>
                {eventLabels.addEndDate}
              </Button>
            </div>
          ) : (
            <>
              <div className="flex flex-row gap-4">
                <div className="flex-1">
                  <NewDatePicker
                    name={'end'}
                    placeholder={eventLabels.eventEndLabel}
                    date={values.end}
                    setDate={(e) => {
                      onChangeDate({ e, field: `parts[${idx}].end`, type: 'date', value: values.end });
                    }}
                    fromYear={+moment().format('YYYY')}
                    toYear={2099}
                  />
                </div>
                <div>
                  <FormInput
                    aria-label="Time"
                    type="time"
                    value={values.end?.format('HH:mm')}
                    name="end"
                    onChange={(e) => {
                      onChangeDate({ e: e.target.value, field: `parts[${idx}].end`, type: 'time', value: values.end });
                    }}
                  />
                </div>
              </div>
              <ErrorLabel>{touched?.end && errors?.end}</ErrorLabel>
            </>
          )}
          <FormInput
            name={`parts[${idx}].location`}
            placeholder={eventLabels.locationLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.location}
            error={errors?.location}
            touched={touched?.location}
          />
          <FormInput
            name={`parts[${idx}].description`}
            placeholder={eventLabels.descLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.description}
            error={errors?.description}
            touched={touched?.description}
          />

          {event_participants_limitation !== 0 && (
            <div className="flex flex-col mt-2">
              <div className="flex gap-x-2 mb-4 ml-3">
                <Checkbox checked={enableParticipantsLimit} onClick={addMaxMembers} />
                <Label>Teilnehmerzahl ist begrenzt</Label>
              </div>
              {enableParticipantsLimit && (
                <div>
                  <FormInput
                    name={`parts[${idx}].maxMembers`}
                    placeholder={'Maximale Teilnehmeranzahl'}
                    type="number"
                    onChange={handleChange}
                    value={values?.maxMembers}
                    error={errors?.maxMembers}
                    touched={touched?.maxMembers}
                  />
                </div>
              )}
            </div>
          )}
          <div className="flex flex-row ">
            <div>
              <FormInput
                // type="text"
                name={`parts[${idx}].price`}
                placeholder={'Preis'}
                onBlur={handlePriceBlur}
                onKeyUp={handleKeyUp}
                onChange={handleChange}
                value={values?.price}
                error={errors?.price}
                touched={touched?.price}
              />
            </div>

            <Label className="ml-1 mt-2 self-center font-normal">EUR</Label>
          </div>
          {values?.questions?.map((survey, surveyIdx) => {
            const addNewOption = () => {
              const newOptions = [...values.questions[surveyIdx].options];
              newOptions[newOptions.length] = { title: '' };
              const newQuestions = [...values.questions];
              newQuestions[surveyIdx].options = newOptions;
              setValues({ ...values });
            };

            return (
              <div key={surveyIdx} className="rounded-md bg-slate-100 p-2 mb-4">
                <div className="flex flex-row justify-between mx-1 mb-3">
                  <h4 className="font-medium">
                    {eventLabels.parts.questionnaire} {surveyIdx + 1}
                  </h4>
                  <Button
                    variant="text"
                    size="text"
                    className="text-red-500"
                    onClick={() => {
                      values.questions.splice(surveyIdx, 1);
                      setValues({ ...values });
                    }}
                  >
                    {eventLabels.parts.questionnaire} {btn.clearBtn.toLowerCase()}
                  </Button>
                </div>

                <Survey
                  parentComponent="eventPart"
                  key={surveyIdx}
                  idx={surveyIdx}
                  values={values.questions[surveyIdx]}
                  setValues={(qValues) => {
                    const upd = values.questions;
                    upd[surveyIdx] = qValues;
                    setValues({ ...values, questions: upd });
                  }}
                  // @ts-ignore
                  errors={errors?.questions?.[surveyIdx]}
                  touched={touched?.questions[surveyIdx]}
                  namePrefix={`parts[${idx}]`}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  addNewOption={addNewOption}
                  survey={survey}
                  surveyIdx={surveyIdx}
                />
              </div>
            );
          })}
          {events_options_possible && (
            <Button
              variant="text"
              size="text"
              // className="ml-1 mt-1 text-primary-btn hover:text-primary-btn/50 duration-75"
              onClick={() => {
                setValues({
                  ...values,
                  questions: values?.questions?.length ? values?.questions.concat(initialSurvey) : [initialSurvey],
                });
              }}
            >
              {eventLabels.parts.addQuestionnaire}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default EventPart;
