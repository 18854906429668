import { FC } from 'react';
import DocumentsGallery from './DocumentsGallery';
import PostGallery from './PostGallery';
import { VideoPlayer } from './VideoPlayer';

export type TMediaSize = 'xs' | 'sm' | 'lg';

type Props = { documents: any; videos: any; images: any; size?: TMediaSize; flexDirection?: 'row' | 'col' };

export const AttachmentsGallery: FC<Props> = ({ documents, videos, images, size = 'lg', flexDirection = 'col' }) => {
  return (
    <div className={`flex flex-${flexDirection} gap-x-2`}>
      {!!images?.length && <PostGallery images={images} videos={videos} size={size} />}
      {!!videos?.length && videos[0]?.url && <VideoPlayer videos={videos} size={size} />}
      {!!documents?.length && <DocumentsGallery files={documents} size={size} />}
    </div>
  );
};

export const mediaWidth = {
  xs: window.innerWidth <= 650 ? window.innerWidth * 0.27 : window.innerWidth <= 1024 ? 126 : 150,
  sm: 180,
  lg: 598,
};

export const mediaHeight = {
  xs: window.innerWidth <= 650 ? window.innerWidth * 0.27 : window.innerWidth <= 1024 ? 126 : 150,
  sm: 180,
  lg: 360,
};
