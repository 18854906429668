import React, { useCallback, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { withStyles, WithStyles, createStyles, ButtonBase } from '@material-ui/core';
import { withConfig, Theme } from '../../config/';
import ZusagenModal from './ZusagenModal';
import { useQuery } from '../../hooks/useQuery';
import { useLanguage } from 'languages/languageContext';
import { useAppSelector } from 'store';
import { useCustomHistory } from 'hooks/useCustomHistory';
import {
  addEventGuestRequest,
  addEventMemberRequest,
  removeEventGuestRequest,
  removeEventMemberRequest,
} from 'store/actions/events';
import { hideResponse, showResponse } from 'store/actions/response';
import Linkify from 'react-linkify';
import DefaultModal from './DefaultModal';
import linkDecorator from 'components/UI/linkDecorator';
import { Button } from 'components/shadcn/button';
import { Label } from 'components/shadcn/label';

const styles = createStyles({
  descItem: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 5,
  },
  descProp: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 16,
    textTransform: 'none',
    color: '#2c73d1',
  },

  likeButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftButton: {
    boxSizing: 'border-box',
    marginRight: '3px',
    borderRadius: '50px 0 0 50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 8px',
    transition: '200ms',
    '&:hover': {
      transform: 'scale(1.06)',
      transition: '200ms',
    },
  },
  rightButton: {
    padding: '12px 8px',
    borderRadius: '0 50px 50px 0',
    display: 'flex',
    minWidth: 40,
    alignItems: 'center',
    justifyContent: 'center',
    transition: '200ms',
    '&:hover': {
      transform: 'scale(1.06)',
      transition: '200ms',
    },
  },
  commentsButton: {
    padding: '13px 10px',
    borderRadius: 50,
  },
  commentsLabel: {
    fontFamily: 'Roboto',
    fontSize: 15,
  },
  likeLabel: {
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: '21px',
    display: 'block',
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  event: any;
  toDetailsHandler?: () => void;
  showComments: boolean;
  showZusagenModal: boolean;
  setShowZusagenModal: (c: boolean) => void;
  showMoreInfo?: boolean;
}

const EventToolbar: React.FC<Props> = ({
  classes,
  theme,
  event,
  showComments = true,
  showZusagenModal,
  setShowZusagenModal,
  toDetailsHandler,
  showMoreInfo,
}) => {
  const { push, customPush, location } = useCustomHistory();
  const {
    eventLabels,
    btn,
    privacy: { restrictions },
  } = useLanguage();
  const dispatch = useDispatch();

  const user = useAppSelector((state) => state.users.user);
  const query = useQuery();

  const email = query.get('email');

  const userId = user._id;
  const eventRegistrationType = event?.registration?.type;
  const isOnWaitList = event?.waitList?.some((item) => item.user === userId);
  const maxParticipantsAchieved = event?.participantsLimit !== 0 && event?.participantsLimit <= event?.members?.length;

  const isJoined = event?.members?.some((item) => item.id === userId);
  const isInvolved = isJoined || isOnWaitList;
  const currentMembers = event?.members;
  const currentGuests = event?.acceptedGuests?.map((guest: any) => guest.email || guest);

  const [interessiertModal, setInteressiertModal] = useState<boolean>(false);

  const eventWithParts = !!event?.parts?.length;

  const showMembersHandler = () => {
    if (user?.isGuest) {
      dispatch(
        showResponse({
          message: restrictions.participantsList,
        }),
      );
      return;
    }
    push('/participants/' + event._id, { event });
  };

  const addMemberHandler = () => {
    if (isInvolved) {
      if (user && user._id) {
        dispatch(removeEventMemberRequest(event._id, user._id));
      }
    } else {
      if (!user._id) {
        setShowZusagenModal(true);
        return;
      } else if (user && user._id) {
        if (eventRegistrationType !== 'app') {
          setInteressiertModal(true);
        }
        const recConfig = !!event.recurringId
          ? {
              recurring: true,
              startDate: event.startDate,
              endDate: event.endDate,
            }
          : undefined;

        dispatch(addEventMemberRequest(event._id, user._id, recConfig));
      }
    }
  };

  const addGuestHandler = (guest = { guestName: '', guestEmail: '', inviter: '' }) => {
    const { guestEmail: email } = guest;
    if (!isJoined) {
      dispatch(addEventGuestRequest(event._id, email, guest));
    }
  };

  const removeGuestHandler = () => {
    if (email && isJoined) {
      dispatch(removeEventGuestRequest(event._id, { email }));
    }
  };

  const zusagenHandler = () => {
    if (maxParticipantsAchieved && !(isOnWaitList || isJoined)) {
      dispatch(
        showResponse({
          title: eventLabels.waitlist,
          message: eventLabels.limitAchieved(event.participantsLimit),
          cancelBtnLabel: btn.ok,
        }),
      );
    }

    if (maxParticipantsAchieved && (isOnWaitList || isJoined)) {
      dispatch(
        showResponse({
          title: eventLabels.leaveWaitlist,
          message: eventLabels.leaveWaitlistDesc,
          options: [
            <Button
              className="w-[220px] bg-primary-btn self-center mb-2"
              onClick={() => {
                addMemberHandler();
                dispatch(hideResponse());
              }}
            >
              {btn.yesUnderstood}
            </Button>,
          ],
        }),
      );
      return;
    }

    if (userId) {
      return addMemberHandler();
    }

    if (isJoined) {
      return removeGuestHandler();
    }
    return setShowZusagenModal(true);
  };

  const acceptEventBtn = () => {
    if (isOnWaitList) {
      return 'Von Warteliste entfernen';
    }

    if (eventRegistrationType !== 'app') {
      return isJoined ? 'Nicht interessiert' : 'Interessiert';
    }
    if (isJoined) {
      return btn.cancelEventBtn;
    }

    return btn.acceptBtn;
  };

  const JoinEventButton = useCallback(() => {
    const isOnCalendarScreen = location.pathname === `/calendar/${event?._id}`;

    if (eventWithParts && isOnCalendarScreen) return <></>;

    if (eventWithParts)
      return (
        <ButtonBase
          onClick={() =>
            customPush('/calendar/' + event._id, {
              eventId: event._id,
            })
          }
          className={classes.leftButton}
          style={{
            backgroundColor: isInvolved ? theme.ERROR_PRIMARY : theme.BUTTON_LIGHT,
            borderRadius: 50,
          }}
        >
          <span
            className={classes.commentsLabel}
            style={{
              color: isInvolved ? theme.BACKGROUND : theme.BUTTON_PRIMARY,
            }}
          >
            Zur Anmeldung
          </span>
        </ButtonBase>
      );

    return (
      <div className="flex flex-col">
        <div className={classes.likeButton} style={{ color: theme.BUTTON_PRIMARY }}>
          <Button
            onClick={zusagenHandler}
            className={classes.leftButton}
            style={{
              backgroundColor: isInvolved ? theme.ERROR_PRIMARY : theme.BUTTON_LIGHT,
            }}
          >
            <span
              className={classes.commentsLabel}
              style={{
                color: isInvolved ? theme.BACKGROUND : theme.BUTTON_PRIMARY,
              }}
            >
              {acceptEventBtn()}
            </span>
          </Button>
          <Button
            className={classes.rightButton}
            style={{
              background: currentMembers?.length > 0 ? theme.BUTTON_LIGHT : theme.BACKGROUND_SECONDARY,
            }}
            onClick={showMembersHandler}
            disabled={!currentMembers?.length && !currentGuests?.length && !event?.waitList?.length}
          >
            <span
              className={classes.likeLabel}
              style={{
                color: theme.BUTTON_PRIMARY,
                marginRight: 5,
              }}
            >
              {currentMembers?.length + (currentGuests ? currentGuests.length : 0)}{' '}
              {!!event?.participantsLimit && `/ ${event?.participantsLimit}`}
            </span>
          </Button>
        </div>
        {!!event?.waitList?.length && (
          <Label className="text-xs self-center mt-1">
            +{event?.waitList?.length} {eventLabels.onWaitlist}
          </Label>
        )}
      </div>
    );
  }, [isInvolved, eventWithParts]);

  return (
    <React.Fragment>
      {currentMembers && currentGuests && (
        <div>
          {showMoreInfo && (
            <ButtonBase style={{ padding: 0 }} onClick={toDetailsHandler}>
              <div className={classes.descItem}>
                <span className={classes.descProp}>{eventLabels.moreInfo}</span>
              </div>
            </ButtonBase>
          )}
          <div className={'py-2 flex flex-col sm:flex-row justify-between items-start gap-2'}>
            <JoinEventButton />
            {user._id && showComments && (
              <div
                className={'rounded-full flex justify-center w-full sm:w-fit'}
                style={{ background: theme.BUTTON_LIGHT }}
              >
                <ButtonBase
                  className={classes.commentsButton}
                  onClick={() =>
                    customPush('/calendar/' + event._id, {
                      eventId: event._id,
                    })
                  }
                >
                  <span className={classes.commentsLabel} style={{ color: theme.BUTTON_PRIMARY }}>
                    {eventLabels.commentsLabel} ({event.comments.length})
                  </span>
                </ButtonBase>
              </div>
            )}
          </div>
        </div>
      )}

      {showZusagenModal && (
        <ZusagenModal
          showModal={showZusagenModal}
          modalHandler={(set: boolean) => {
            setShowZusagenModal(set);
          }}
          submitHandler={(guest: { guestName: string; guestEmail: string; inviter: string }) => {
            addGuestHandler(guest);
          }}
          email={email}
          inviter={''}
        />
      )}
      <DefaultModal
        showModal={interessiertModal}
        onClose={() => setInteressiertModal(false)}
        modalTitle="Keine Anmeldung!"
      >
        <div style={{ textAlign: 'center', padding: '10px 20px' }}>
          Du bist noch nicht angemeldet. Dies ist nur eine Interessensbekundung.{' '}
          <div style={{ fontWeight: 'bold' }}>
            Die Anmeldung erfolgt über:
            <Linkify componentDecorator={linkDecorator}> {event?.registration?.value}</Linkify>
          </div>
        </div>
      </DefaultModal>
    </React.Fragment>
  );
};

export default withStyles(styles)(withConfig(withRouter(EventToolbar)));
